// import { Helmet } from "react-helmet";
import React from "react";
import { Link } from "gatsby";

const DisclaimerPage = () => {
  return (
    <div className="py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
      <div className="max-w-max lg:max-w-7xl mx-auto">
        <div className="relative z-10 mb-8 md:mb-2 md:px-6">
          <div className="text-base max-w-prose lg:max-w-none">
            <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
              Disclaimer
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Please read our disclaimer before using our site.
            </p>
          </div>
        </div>
        <div className="relative">
          <svg
            className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)"
            />
          </svg>
          <svg
            className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)"
            />
          </svg>
          <div className="relative md:bg-white md:p-6">
            <div className="lg:grid lg:grid-cols-2 lg:gap-6">
              <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                <div className="text-2xl">
                  Disclaimer for SideHustleTool.com - owned and operated by DGF
                  DIGITAL INC.
                </div>
                <p>
                  If you require any more information or have any questions
                  about our site's disclaimer, please feel free to contact us by
                  email at <Link to="/contact">contact@sidehustletool.com</Link>
                </p>
                <div className="text-2xl pt-2">
                  Disclaimers for SideHustleTool.com - owned and operated by DGF
                  Digital Inc.
                </div>
                <div>
                  <p>
                    All the information on this website - sidehustletool.com -
                    is published in good faith and for general information
                    purpose only. We assume no responsibility for errors or
                    omissions in its content. The owners of this site (DGF
                    Digital Inc.) do not make any warranties about the
                    completeness, reliability and accuracy of this information.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    In no event shall DGF Digital Inc. be liable for any
                    special, direct, indirect, consequential or incidental
                    damages whatsoever, whether in an action of contract,
                    negligence or other tort arising out of or in connection
                    with the use of the service or the contents of the service.
                    The owners of this site reserve the right to make additions,
                    deletions or modifications to the contents of the service at
                    any time without prior notice. DGF Digital Inc. does not
                    warrant that the website is free of viruses or other harmful
                    components.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Any action you take upon the information you find on this
                    website (SideHustleTool.com), is strictly at your own risk.
                    DGF Digital Inc. will not be liable for any losses and/or
                    damages in connection with the use of our website. Our
                    Disclaimer was generated with the help of the{" "}
                    <a href="https://www.privacypolicyonline.com/disclaimer-generator/Disclaimer">
                      Generator Online
                    </a>{" "}
                    and the{" "}
                    <a href="https://www.disclaimer-generator.com/">
                      Free Disclaimer Generator
                    </a>
                    .
                  </p>
                </div>
              </div>
              <div className="mt-6 prose prose-indigo prose-lg text-gray-500 lg:mt-0">
                <p>
                  From our website, you can visit other websites by following
                  hyperlinks to such external sites. While we strive to provide
                  only quality links to useful and ethical websites, we have no
                  control over the content and nature of these sites. These
                  links to other websites do not imply a recommendation for all
                  the content found on these sites. Site owners and content may
                  change without notice and may occur before we have the
                  opportunity to remove a link which may have gone 'bad'. Please
                  note the owners of this site do not guarantee the accuracy,
                  relevance, timeliness or completeness of any information on
                  these external sites.
                </p>
                <div className="pt-3">
                  <p>
                    Please be also aware that when you leave our website, other
                    sites may have different privacy policies and terms which
                    are beyond our control. Please be sure to check the Privacy
                    Policies of these sites as well as their "Terms of Service"
                    before engaging in any business or uploading any
                    information.
                  </p>
                </div>
                <div className="text-2xl pt-2">Consent</div>
                <p>
                  By using our website, you hereby consent to our disclaimer and
                  agree to its terms.
                </p>
                <div className="text-2xl pt-2">Update</div>
                <p>
                  Should we update, amend or make any changes to this document,
                  those changes will be prominently posted here.
                </p>
              </div>
            </div>
            <div className="mt-8 inline-flex rounded-md shadow">
              <a
                href="https://sidehustletool.com/contact/"
                className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisclaimerPage;
